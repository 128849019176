<template>
  <div style="width: 100%">


    <div class="back-img">
      <div style="font-size: 50px; color: #fff">KEYNOTE SPEAKERS</div>
    </div>

    <div class="keynote-speakers">
      <el-row>
        <el-col :md="8" :sm="12" v-for="speaker in speakers1">
          <div class="speaker">
            <div class="speaker-avatar">
              <img :src="require('assets/images/keynote/'+speaker.avatar+'.png')" alt="">
            </div>
            <div class="speaker-name">{{speaker.name}}</div>
            <div class="speaker-affiliation">{{speaker.affiliation}}</div>
            <div class="speaker-intro">{{speaker.intro}}</div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :md="8" :sm="12" v-for="speaker in speakers2">
          <div class="speaker">
            <div class="speaker-avatar">
              <img :src="require('assets/images/keynote/'+speaker.avatar+'.png')" alt="">
            </div>
            <div class="speaker-name">{{speaker.name}}</div>
            <div class="speaker-affiliation">{{speaker.affiliation}}</div>
            <div class="speaker-intro">{{speaker.intro}}</div>
          </div>
        </el-col>
      </el-row>

    </div>

  </div>
</template>

<script>
export default {
  name: "Keynote",
  data(){
    return{
      speakers1:[
        {
          avatar:'speaker1',
          name:'Yusheng Xue',
          affiliation:'(Academician of China Engineering Academy)',
          intro:'Yusheng Xue as an Academician of Chinese Academy of Engineering, the chief engineer of State Grid Automation Research Institute. He received the Jiangsu Science and Technology Outstanding Contribution Award in 2010. He is the editor-in-chief of the award-winning journal "Double High Journal". He also works as adjunct professor at 14 universities. He has participated as the project leader in 8 national projects and pioneered the quantitative theory of motion stability of non-autonomous systems and the quantitative algorithm of power system transient stability.\n'
        },
        
        {
          avatar:'speaker2',
          name:'Mohammad Shahidehpour',
          affiliation:'(Fellow of American Academy of Engineering)',
          intro:'Dr. Mohammad Shahidehpour joined Illinois Institute of Technology (Illinois Tech) in 1983 where he is presently a University Distinguished Professor. He also serves as the Bodine Chair Professor, Director of the Robert W. Galvin Center for Electricity Innovation, and Associate Director of the Wanger Institute for Sustainable Energy Research (WISER) at Illinois Tech. He is a Fellow of IEEE, Fellow of the American Association for the Advancement of Science (AAAS), Fellow of the National Academy of Inventors (NAI), Laureate of KIA (Khwarizmi International Award), and an elected member of the US National Academy of Engineering. He is listed as a highly cited researcher on the Web of Science (ranked in the top 1% by citations demonstrating significant influence among his peers). '
        },
        {
          avatar:'speaker3',
          name:'Yulong Ding',
          affiliation:'(Fellow of Royal Academy of Engineering)',
          intro:'Yulong Ding holds the founding Chamberlain chair of Chemical Engineering and Highview-RAEng chair of cryogenic energy storage and is director of Birmingham Centre for Energy Storage. His research has been cross-disciplinary in particular at the interface of chemical engineering, chemistry, energy engineering, materials science & engineering. He has been a principal and a co-investigator of projects with a total exceeding £20M over the past 10 years. He has published over 400  technical papers with over 9000 citations (H index ~ 42) and 10 book chapters. He was an in inventor of the cryogenic energy storage technology, which was awarded the 2011 ‘The Engineer’ magazine Energy & Environmental and Grand Prix awards, and 2012 Rushlight Energy Environmental and Power Generation and Transmission awards.'
        }
        // {
        //   avatar:'speaker4',
        //   name:'Licheng Li',
        //   affiliation:'(Academician of China Engineering Academy)',
        //   intro:'Licheng Li is expert in power grid engineering and DC transmission. He is currently the chairman of the Expert Committee of China Southern Power Grid Corporation. He participated in and organized the world\'s first technical research of ±800 kV DC transmission project. He has received 2 national awards and 7 ministerial awards. He has written more than 10 major engineering technical reports, published more than 40 academic papers, authorized 8 patents, and co-published one monograph.'
        // },
        ]
      ,
      speakers2:[
        
        {
          avatar:'speaker5',
          name:'Chongqing Kang',
          affiliation:'(Professor, Tsinghua University)',
          intro:'Chongqing Kang is the Fellow of IET and Senior Member of IEEE. He was receiver of the National Science Fund for Distinguished Young Scholars. His main research interests include power system planning and operation optimization, low-carbon power technology, renewable energy, load forecasting, and power market. He presided over 4 projects of the National Natural Science Foundation of China and served on the editorial board of several journals. He has published 3 first-author monographs, and over 200 papers with  over 5400 citations (H index ~ 42).'
        },
        {
          avatar:'speaker6',
          name:'Tianshu Bi',
          affiliation:'(Professor, North China Electric Power University)',
          intro:'Tianshu Bi is the member of IEEE Power Engineering Committee and member of Executive Committee of the Institute on Power Systems and Communication Systems Infrastructures for the Future (CRIS). She is mainly engaged in research on power system protection and control, wide-area synchronous phasor measurement technology and application, and fault diagnosis. She has published more than 70 academic papers in authoritative academic journals and international conferences. She has been invited to give special reports at high-level international conferences (such as IEEE General Meeting) on many occasions. '
        },
        {
          avatar:'speaker7',
          name:'Wei Gu',
          affiliation:'(Professor, Southeast University)',
          intro:'Professor Gu is the vice dean of School of Electrical Engineering at Southeast University, China. He also is the director of the Institute of Distributed Generations and Active Distribution Networks and vice director of Power System Simulation Committee of China Simulation Federation. His research interests include power system simulation, distributed generations and microgrids, integrated energy system. Prof. Gu has participated as the project leader in 5 national projects including key projects of NSFC. He has received the first prize of China Simulation Federation Science and Technology Award and the Gold Medal of Geneva International Invention Exhibition.'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.back-img{
  background-image: url("../assets/images/keynote/bcg.png");
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keynote-speakers{
  margin: 50px 100px;

  .speaker{
    margin: 50px;

    .speaker-avatar,.speaker-name,.speaker-affiliation{
      text-align: center;
      font-family: FZYDZHJW;
      line-height: 25px;
      font-weight: bold;
    }

    .speaker-name{
      font-size: 19px;
      margin-top: 30px;
    }

    .speaker-affiliation{
      font-size: 13px;
      margin-bottom: 25px;
    }

    .speaker-intro{
      font-size: 12px;
      color: #666;
      font-weight: 400;
      line-height: 20px;
      opacity: 0.5;
      font-family: Microsoft YaHei;
    }
  }
}

</style>
